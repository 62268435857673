export default {
  fontFamily: {
    serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
    "sans-serif": '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
    mono: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
  },
  maxContentWidth: 1080,
  defaultFontFamilyVariant: 'sans-serif',
  colors: {
    white: {
      100: '#fff',
      200: '#fff',
      300: '#fff',
      400: '#fff',
      500: '#fff',
      600: '#fff',
      700: '#fff',
      800: '#fff',
      900: '#fff',
    },
    black: {
      100: '#111',
      200: '#111',
      300: '#111',
      400: '#111',
      500: '#111',
      600: '#111',
      700: '#111',
      800: '#111',
      900: '#111',
    },
    primary: {
      100: '#E6FFFA',
      200: '#B2F5EA',
      300: '#81E6D9',
      400: '#4FD1C5',
      500: '#38B2AC',
      600: '#319795',
      700: '#2C7A7B',
      800: '#285E61',
      900: '#234E52',
    },
    secondary: {
      100: '#EBF8FF',
      200: '#BEE3F8',
      300: '#90CDF4',
      400: '#63B3ED',
      500: '#4299E1',
      600: '#3182CE',
      700: '#2B6CB0',
      800: '#2C5282',
      900: '#2A4365',
    },
    text: {
      100: '#F7FAFC',
      200: '#EDF2F7',
      300: '#E2E8F0',
      400: '#CBD5E0',
      500: '#A0AEC0',
      600: '#718096',
      700: '#4A5568',
      800: '#2D3748',
      900: '#1A202C',
    },
    gray: {
      100: '#F7FAFC',
      200: '#EDF2F7',
      300: '#E2E8F0',
      400: '#CBD5E0',
      500: '#A0AEC0',
      600: '#718096',
      700: '#4A5568',
      800: '#2D3748',
      900: '#1A202C',
    },
    red: {
      100: '#FFF5F5',
      200: '#FED7D7',
      300: '#FEB2B2',
      400: '#FC8181',
      500: '#F56565',
      600: '#E53E3E',
      700: '#C53030',
      800: '#9B2C2C',
      900: '#742A2A',
    },
    orange: {
      100: '#FFFAF0',
      200: '#FEEBC8',
      300: '#FBD38D',
      400: '#F6AD55',
      500: '#ED8936',
      600: '#DD6B20',
      700: '#C05621',
      800: '#9C4221',
      900: '#7B341E',
    },
    yellow: {
      100: '#FFFFF0',
      200: '#FEFCBF',
      300: '#FAF089',
      400: '#F6E05E',
      500: '#ECC94B',
      600: '#D69E2E',
      700: '#B7791F',
      800: '#975A16',
      900: '#744210',
    },
    green: {
      100: '#F0FFF4',
      200: '#C6F6D5',
      300: '#9AE6B4',
      400: '#68D391',
      500: '#48BB78',
      600: '#38A169',
      700: '#2F855A',
      800: '#276749',
      900: '#22543D',
    },
    teal: {
      100: '#E6FFFA',
      200: '#B2F5EA',
      300: '#81E6D9',
      400: '#4FD1C5',
      500: '#38B2AC',
      600: '#319795',
      700: '#2C7A7B',
      800: '#285E61',
      900: '#234E52',
    },
    blue: {
      100: '#EBF8FF',
      200: '#BEE3F8',
      300: '#90CDF4',
      400: '#63B3ED',
      500: '#4299E1',
      600: '#3182CE',
      700: '#2B6CB0',
      800: '#2C5282',
      900: '#2A4365',
    },
    indigo: {
      100: '#EBF4FF',
      200: '#C3DAFE',
      300: '#A3BFFA',
      400: '#7F9CF5',
      500: '#667EEA',
      600: '#5A67D8',
      700: '#4C51BF',
      800: '#434190',
      900: '#3C366B',
    },
    purple: {
      100: '#FAF5FF',
      200: '#E9D8FD',
      300: '#D6BCFA',
      400: '#B794F4',
      500: '#9F7AEA',
      600: '#805AD5',
      700: '#6B46C1',
      800: '#553C9A',
      900: '#44337A',
    },
    pink: {
      100: '#FFF5F7',
      200: '#FED7E2',
      300: '#FBB6CE',
      400: '#F687B3',
      500: '#ED64A6',
      600: '#D53F8C',
      700: '#B83280',
      800: '#97266D',
      900: '#702459',
    },
    transparent: {
      100: 'transparent',
      200: 'transparent',
      300: 'transparent',
      400: 'transparent',
      500: 'transparent',
      600: 'transparent',
      700: 'transparent',
      800: 'transparent',
      900: 'transparent',
    }
  },
  typography: {
    sizes: {
      xs: '.75rem',
      sm: '.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '4.5rem',
      '8xl': '5.0rem',
    },
  },
  spacing: {
    px: '1px',
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
  },
  overrides: {}
}
