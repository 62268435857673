import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from 'styled-components';
import { Flex, Text } from 'pretty-decent-ui'
import React from "react"
import Logo from "./Logo"
import StyledLink from "./styles/StyledLink"

const A = styled.a`
  color: ${props => props.theme.colors.purple[700]};
  /* text-decoration: underline; */
  border-bottom: 1px solid;
  border-bottom-color: ${props => props.theme.colors.purple[700]};

  code {
    /* text-decoration: underline; */
    border-bottom: 1px solid;
    border-color: ${props => props.theme.colors.purple[700]};
    transition: 0.3s all ease;
  }

  &:hover {
    color: ${props => props.theme.colors.purple[500]};
    border-bottom-color: ${props => props.theme.colors.purple[500]};

    code {
      color: ${props => props.theme.colors.purple[500]};
      border-color: ${props => props.theme.colors.purple[500]};
    }
  }
`

const Header = ({ siteTitle }) => (
  <header className="py-4">
    <Flex
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex>
        <Link to="/">
          <Logo size={40} />
        </Link>
      </Flex>
      <Flex>
        <Text size="xl">
          <A as={Link} to="/posts">
            Posts
          </A>
        </Text>
      </Flex>
    </Flex>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
