import React from "react"
import styled from "styled-components"

const Path = styled.path`
  fill: ${props => (props.theme.overrides && props.theme.overrides.backgroundColor) || "#fcfcfc"};
`

export default ({ size = 24 }) => (
  <svg
    viewBox="0 0 24 24"
    height={`${size}px`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: "0 auto" }}
  >
    <circle cx="12" cy="12" r="12" />
    <Path d="M12.367949 9.960787l-1.900889 5.336712h-.716117L7.779387 9.762182h-.017666v5.535317h-.795715V8.643936h1.149308l1.989287 5.59123 1.998088-5.59123h1.149308v3.271646l3.156326-3.271646h1.087443l-2.661205 2.68379 2.76727 3.969773h-1.06091l-2.281014-3.364057-1.00791.990955V15.2975h-.884048V9.960787z" />
  </svg>
)
