import React from 'react';
import styled from 'styled-components';

export default styled.a`
  color: rgb(255, 167, 250);
  text-decoration: none;
  transition: all 0.5s ease 0s;
  &:hover {
    color: rgb(255, 68, 251);
  }
`
