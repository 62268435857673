import React from 'react';
import { Flex, Text } from 'pretty-decent-ui'

export default () => {
  return (
    <Flex justifyContent="center" className="py-4">
      <Text variant="gray" shade={700}>©2020 Matthew Knudsen</Text>
    </Flex>
  )
}
