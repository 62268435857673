import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwlLight'
export default ({ children, className = '', ...rest }) => {
  const language = className.replace(/language-/, '')
  return (
    <Highlight {...defaultProps} theme={theme} code={children} language={language || 'javascript'}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={`${className} shadow`} style={{ ...style, padding: '20px', overflowX: 'auto' }}>
          {tokens.map((line, i) => {
            const isLineEmpty = line.every(token => token.empty)
            const isLastLine = i === tokens.length - 1;
            if (isLineEmpty && isLastLine) return null;
            return (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            )
          }
          )}
        </pre>
      )}
    </Highlight>
  )
}
