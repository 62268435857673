import React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { GlobalStyle, Text } from "pretty-decent-ui"
import CodeBlock from "../components/CodeBlock"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer"
import theme from "../theme-posts"

import "../components/layout.css"

const H1 = ({ children }) => (
  <Text as="h1" className="pt-12" fontWeight={600} xs="3xl" md="4xl">
    {children}
  </Text>
)
const H2 = ({ children }) => (
  <Text as="h2" className="pt-12" fontWeight={600} xs="2xl" md="3xl">
    {children}
  </Text>
)
const H3 = ({ children }) => (
  <Text as="h3" className="pt-12" fontWeight={600} xs="xl" md="2xl">
    {children}
  </Text>
)
const H4 = ({ children }) => (
  <Text as="h4" className="pt-12" fontWeight={600} xs="lg" md="xl">
    {children}
  </Text>
)
const P = ({ children }) => (
  <Text className="py-3" as="p" xs="xl" md="xl">
    {children}
  </Text>
)

const BlockQuote = styled.blockquote`
  border-left: 3px solid;
  border-color: ${props => props.theme.colors.purple[700]};
  margin: 1em 0;
  background: ${props => props.theme.colors.purple[100]};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  p {
    padding: 1em;
    color: ${props => props.theme.colors.purple[900]};
  }
`

const A = styled.a`
  color: ${props => props.theme.colors.purple[700]};
  /* text-decoration: underline; */
  border-bottom: 1px solid;
  border-bottom-color: ${props => props.theme.colors.purple[700]};

  code {
    /* text-decoration: underline; */
    border-bottom: 1px solid;
    border-color: ${props => props.theme.colors.purple[700]};
    transition: 0.3s all ease;
  }

  &:hover {
    color: ${props => props.theme.colors.purple[500]};
    border-bottom-color: ${props => props.theme.colors.purple[500]};

    code {
      color: ${props => props.theme.colors.purple[500]};
      border-color: ${props => props.theme.colors.purple[500]};
    }
  }
`

const ListItem = ({ children, ...rest }) => (
  <li className="py-1" {...rest}>
    <Text size="xl">{children}</Text>
  </li>
)

const shortcodes = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: P,
  Text,
  ul: props => <ul className="px-2 py-2" {...props} />,
  ol: props => <ol className="px-4 py-2" {...props} />,
  li: ListItem,
  blockquote: BlockQuote,
  a: props => (
    <A
      as={props.href.startsWith("https://") ? "a" : Link}
      target={props.href.startsWith("https://") ? "_blank" : ""}
      rel={props.href.startsWith("https://") ? "noopener noreferrer" : ""}
      to={props.href}
      {...props}
    />
  ),
  // pre: props => <div {...props} />,
  code: CodeBlock,
}

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  line-height: inherit;
  color: ${props => props.theme.colors.gray[900]};
`

const GlobalMarkdownStyles = createGlobalStyle`
  html {
    line-height: 1.8 !important;
  }

  ol li {
    list-style: decimal;
    padding-left: 8px;
  }

  /* FOR prism-react-renderer */
  pre, .token, .token-line {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    border-radius: 5px;
  }
  pre {
    margin: 16px 0 !important;
  }
  code {
    background-color: ${props => props.theme.colors.purple[100]};
    color: ${props => props.theme.colors.purple[800]};
    padding: 2px 4px;
    border-radius: 3px;
  }
  /* END FOR prism-react-renderer */
`

const isProd = process.env.NODE_ENV === "production"

export default ({ children, pageContext }) => (
  <MDXProvider components={shortcodes}>
    <ThemeProvider theme={theme}>
      <SEO title={pageContext.frontmatter.title} />
      <GlobalStyle />
      <GlobalMarkdownStyles />
      <Container className="xs:px-12 md:px-0">
        <Header />

        <H1 className="pt-12">{pageContext.frontmatter.title}</H1>
        {!pageContext.frontmatter.published && isProd ? (
          <div>
            Oh no! You've found your way to an unfinished draft. Send us a note
            and yell at us to finish it ✌️
          </div>
        ) : (
          children
        )}
      </Container>
      <Footer />
    </ThemeProvider>
  </MDXProvider>
)
